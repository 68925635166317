<template>
  <div class="random-quote green-block block-container">
    <div class="left-block small-block">
      <img :src="require(`../assets/images/${quote.img}`)">
    </div>
    <div class="right-block large-block">
      <p class="quote">{{ quote.text }}</p>
      <p class="quote-name">{{ quote.name }}</p>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
export default {
  name: 'RandomQuote',
  setup() {
    const data = inject('data');
    const quote = data.staticData.carousel[Math.floor(Math.random() * data.staticData.carousel.length)];

    return {
      // static data
      quote,
    }
  },
}
</script>

<style lang="scss" scoped>
.random-quote {
  .quote {
    background: url('../assets/images/quotes.png') no-repeat center top transparent;
    background-size: 100% auto;
    font-family: 'Roboto Slab',serif;
    font-size: 20px;
    padding: 0 60px;
    margin: 20px 0;
  }

  .quote-name {
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
  }
}
</style>