<template>
  <div class="check-domain-input-wrapper column">
    <h3>Check domain</h3>
    <p>Here will be some special text.</p>
  </div>
</template>

<script>
export default {
  name: 'CheckDomainInput'
}
</script>

<style lang="scss" scoped>
.check-domain-input-wrapper {
  margin-bottom: 15px;
}
</style>