<template>
  <div class="container">
    <span v-html="breadcrumbs"></span>
  </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router';

export default {
  name: 'Breadcrumbs',
  setup() {
    const router = useRouter()
    const route = useRoute();

    let breadcrumbs = route.path.split('/').filter(p => !!p);
    breadcrumbs = breadcrumbs.reduce((acc, breadcrumb, index) => {
      const breadcrumbRoute = router.options.routes.find(r => r.name === breadcrumb);
      if (breadcrumbRoute) {
        return `${acc} » ${index !== breadcrumbs.length - 1 ? `<a href="${breadcrumbRoute.path}">` : ''}${breadcrumbRoute.meta.title}${index !== breadcrumbs.length - 1 ? `</a>` : ''}`;
      }

      return acc;
    }, `<a href="/">Home</a>`);

    return {
      // static data
      breadcrumbs,
    }
  }
}
</script>

<style lang="scss" scoped>
span {
  font-size: 13px;
  margin-bottom: 10px;
}
</style>