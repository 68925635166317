<template>
  <div class="column right-block small-block">
    <div class="green-block">
      <h3>Uw domeinnaam bij Hosting on Demand</h3>
      <ul>
        <li>Altijd enorm voordelig</li>
        <li>Domeincheck voor alle extensies</li>
        <li>Nog meer voordeel door staffels vanaf 50 domeinen</li>
        <li>Online alles snel en zelf geregeld</li>
      </ul>
    </div>
    <div class="green-block">
      <h3>Veel gestelde vragen over domeinnamen</h3>
      <ExpansionPanels
        :panels="questions.map(q => q.title)"
      >
        <template
          v-for="(question, index) in questions"
          :key="index"
          v-slot:[questionSlotName(index)]
        >
          <div v-html="question.content"></div>
        </template>
      </ExpansionPanels>
    </div>
    <div class="green-block">
      <h3>Meer informatie over domeinnamen</h3>
      <ul>
        <li><router-link to="/domeinnamen/alle-domeinnaam-extensies/">Alle Domeinnaam-extensies</router-link></li>
        <li><router-link to="/domeinnamen/domeinnaam-overdragen/">Domeinnaam overdragen: overnemen met code</router-link></li>
        <li><router-link to="/domeinnamen/prijzen-domeinnamen/">Prijzen domeinnamen</router-link></li>
        <li><router-link to="/domeinnamen/domeinnaam-verhuizen/">Domeinnaam verhuizen: naar een andere provider</router-link></li>
        <li><router-link to="/domeinnamen/domeinnaam-doorsturen/">Domeinnaam doorsturen: doorverwijzen naar een andere website</router-link></li>
        <li><router-link to="/klantenservice/">Klantenservice</router-link></li>
        <li><router-link to="/voorwaarden/">Voorwaarden</router-link></li>
        <li><router-link to="/ssl-certificaten/">SSL-certificaten</router-link></li>
        <li><router-link to="/ssl-certificaten/wildcard-ssl-certificaat/">Wildcard SSL-certificaat</router-link></li>
        <li><router-link to="/ssl-certificaten/multi-domein-ssl-certificaat/">Multi-domein SSL-certificaat</router-link></li>
        <li><router-link to="/domeinnamen/alle-domeinnaam-extensies/">Alle Domeinnaam-extensies</router-link></li>
        <li><router-link to="/domeinnamen/alle-domeinnaam-extensies/nl-domeinnaam-registreren/">.nl domeinnaam registreren</router-link></li>
        <li><router-link to="/domeinnamen/alle-domeinnaam-extensies/com-domeinnaam-registreren/">.com domeinnaam registreren</router-link></li>
        <li><router-link to="/domeinnaam-vrij/">Domeinnaam vrij</router-link></li>
        <li><router-link to="/goedkope-domeinnaam/">Goedkope domeinnaam</router-link></li>
        <li><router-link to="/goedkope-hosting/">Goedkope hosting</router-link></li>
        <li><router-link to="/wat-is-mijn-ip-adres/">Wat is mijn IP adres</router-link></li>
        <li><router-link to="/notice-and-take-down-procedure-hostingondemand/">Notice-and-Take-Down-procedure Hostingondemand</router-link></li>
        <li><router-link to="/ntd-formulier/">NTD Formulier</router-link></li>
        <li><router-link to="/verwerkersovereenkomst-hosting-on-demand/">Verwerkersovereenkomst Hosting on Demand</router-link></li>
        <li><router-link to="/supportaanvraag/">Support Aanvraag</router-link></li>
        <li><router-link to="/support-ticket/">Support Ticket</router-link></li>
        <li><router-link to="/klantenportaal/">Klantenportaal</router-link></li>
        <li><router-link to="/domeinnamen/">Domeinnamen</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

import ExpansionPanels from '@/components/ExpansionPanels.vue';

export default {
  name: 'DomainNameRightSideBlocks',
  components: {
    ExpansionPanels,
  },
  setup() {
    const data = inject('data');
    const questions = data.staticData.questions.filter(q => q.tags.split(' ').includes('domain-name'));

    function questionSlotName(index) {
      return `content-text-${index}`;
    }

    return {
      // static data
      questions,

      // methods
      questionSlotName,
    };
  },
}
</script>