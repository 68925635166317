<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>Domeinnaam verhuizen: naar een andere provider</h1>
        <CheckDomainInput/>
        <div>
          <p>
            Een domeinnaam verhuizen is eenvoudig, wij maken dit u graag gemakkelijk. Wilt u een domeinnaam naar Hosting on Demand verhuizen of een bij ons geregistreerde domeinnaam elders onderbrengen? Geen probleem, we zijn u er in de beide gevallen graag mee van dienst.
            <img
              class="framed-image"
              style="float: right; margin-left: 20px;"
              src="../../assets/images/dozen-e1448658458389.png"
              height="154"
              width="254"
              title="domein verhuizen"
              alt="domein verhuizen"
            >
          </p>
          <p>Een domein verhuizen vraagt om een goede voorbereiding, vooral wanneer het gaat om de verhuizing inclusief <router-link to="/">een website</router-link>. De verhuizing van de domeinnaam op zich is eenvoudig, daarvoor geldt hetzelfde als tijdens de <router-link to="/domeinnamen/domeinnaam-overdragen/">overdracht van een domeinnaam</router-link>.</p>
          <p><i>“Heel fijn dat de verhuizing van mijn domeinnaam en website zo snel geregeld was. Naadloos over op het nieuwe pakket, zodat ik nu altijd kan rekenen op jullie goede service. Echt een warm bad bij Hosting on Demand, ik verhuis binnenkort ook mijn andere websites en kan het iedereen aanraden,”</i> aldus een klant waarvoor we de domeinnaam en website verhuisden.</p>
        </div>
        <h2>Domein en website verhuizen</h2>
        <p>Een domein verhuizen is eenvoudig, zelfs inclusief een complete website. Wilt u alleen een domeinnaam verhuizen of een domein met een website verhuizen? In dat tweede geval is het belangrijk om een back-up van de website te maken, zodat we die weer op de juiste wijze kunnen herstellen. Een website bestaat uit een domeinnaam en de website-bestanden. De verhuizing van de domeinnaam vraagt om een verhuiscode, zoals u die ook kunt overdragen aan een nieuwe houder. De verhuizing van de website zelf vraagt om het overzetten van de websitebestanden van een oude server (en provider) naar een nieuwe.</p>
        <p>Uw voordelen als wij uw domein verhuizen:</p>
        <ul>
          <li>Uitstekende begeleiding tijdens verhuizing</li>
          <li>Snelle verhuizing, minimale downtime</li>
          <li>Verhuizing met zorg: oog voor details</li>
        </ul>
        <p>We helpen u graag uw domeinnaam te verhuizen of complete website over te zetten. We letten tegelijkertijd op de mogelijkheid om meteen een SSL-certificaat toe te voegen of er op een andere manier voor te zorgen dat u optimaal gebruik kunt maken van onze pakketten voor webhosting en de goedkope domeinnamen.</p>
        <h2>Domeinnaam verhuizen met onze begeleiding</h2>
        <p>Uw domein verhuizen met onze begeleiding werkt heel eenvoudig. Geef ons aan dat u een domein wilt verhuizen, bijvoorbeeld van een website die u nu nog elders heeft geregistreerd. We geven u stap voor stap aan wat er dient te gebeuren, waarbij we u desgewenst kunnen ondersteunen.</p>
        <p>Op die manier helpen we u een domein te verhuizen, inclusief website of op basis van alleen de domeinnaam. Wat uw wensen ook zijn, wij zorgen ervoor dat u de URL of de complete website van de oude locatie naar de nieuwe kunt (laten) verhuizen.</p>
        <RandomQuote/>
      </div>
      <DomainNameRightSideBlocks/>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DomainNameRightSideBlocks from '@/components/DomainNameRightSideBlocks.vue';
import CheckDomainInput from '@/components/CheckDomainInput.vue';
import RandomQuote from '@/components/RandomQuote.vue';

export default {
  name: 'TransferDomainNameToAnotherProvider',
  components: {
    Breadcrumbs,
    DomainNameRightSideBlocks,
    CheckDomainInput,
    RandomQuote,
  },
}
</script>